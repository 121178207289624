const state = {
    token:null,
    dateToken: null,
    pathname:location.pathname.split("/")[2],
    isWorkingClient: false,
    isShowCardsClient: false, //Se usa para indicar cuando la informacion de las categorias ya se recibio del servidor y ya puede mostrarse, si no se usa nos marca eror de uerrer renderizar algo que aun esta en null
    isServiceInactivoFromServer: false, //nos indica si el serviceInfo ya se encuentra inactivo en el servidor, esta bandera es usada para bloquear el select de status del formulario de edicion 
    isShowModalEditPassClient:false,
    isEditionMode : true, //indica si el componente panelConfigTramite se mostrara en modo edicion o agregar nuevo tramite
    perfilClient:{
        nombre:null
    },
    administradores:[],
    msjErrorLoginClient:{
        msj:"",
        time:0
    },
    msjErrorClient:{
        msj:"",
        time:0,
        isShowMsjInModal:false
    },
    categorias:null,//Alamcena el catalogo
    categoriasList:null,//almacena la lista de ctaegorias para editarlas
    categoriaNueva:{
        idCategoria:0,
        icon:"documento.png",
        nombreCategoria:""
    },//almacena la nueva categoria por registrar
    currentTramiteInfo:null
}

// mutations
const mutations = {
    updateIsEditionMode(state, newState){
        state.isEditionMode = newState;
    },
    clearCategoriaNueva(state){
        state.categoriaNueva={
            idCategoria:0,
            icon:"documento.png",
            nombreCategoria:""

        }
    },
    updateIsShowCardsClient(state, newIsShowCardsClient){
        state.isShowCardsClient = newIsShowCardsClient;
    },
    updateIsShowModalEditPassClient(state, newIsShow){
        state.isShowModalEditPassClient = newIsShow;
    },
    updateContraseñaAdmin(state, newContraseña){
        for(var i=0; i<state.administradores.length; i++){
            if(state.administradores[i].idAdmin==newContraseña[0]){
                state.administradores[i].pass = newContraseña[1];
            }
        }
    },
    updateAdministradores(state, newAdministradores){
        state.administradores = newAdministradores;
    },
    updateMsjErrorClient(state, newMsjError){
        state.msjErrorClient.msj=newMsjError.msj;
        state.msjErrorClient.time=newMsjError.time;
        state.msjErrorClient.isShowMsjInModal=newMsjError.isShowMsjInModal;
    },
    updateTimeMsjErrorClient(state, newTime){
        state.msjErrorClient.time = newTime;
    },
    updateTimeMsjErrorLoginClient(state, newTime){
        state.msjErrorLoginClient.time = newTime;
    },
    updateMsjErrorLoginClient(state, newMsjErrorLogin){
        state.msjErrorLoginClient.msj=newMsjErrorLogin.msj;
        state.msjErrorLoginClient.time=newMsjErrorLogin.time;
    },
    cleanPerfilClient(state){
        state.perfilClient.nombre=null;
        state.token=null;
        state.categorias=null;
        state.currentTramiteInfo=null;
    },
    updatePerfilClient(state, newPerfilClient){
        state.perfilClient.nombre=newPerfilClient.nombre;
    },
    updateTokenClient(state, newToken){
        state.token='Bearer '+newToken;
        state.dateToken = new Date().getTime();
    },
    updateIsWorkingClient(state, newIsWorkingClient){
        state.isWorkingClient = newIsWorkingClient;
    },
    updateAdministrador(state, newAdministrador){
        state.currentTramiteInfo.administrador = newAdministrador;
    },
    updateCurrentTramiteInfo (state, newCurrentTramiteInfo) {
        state.currentTramiteInfo = newCurrentTramiteInfo; 
    },
    addEmptyReqToCurrentService(state, numNewReq){
        state.currentTramiteInfo.requisitos.push({
            descripcion: "",
            idRequisito: 0,
            linkFormato: "",
            nombreRequisito: "",
            numero: numNewReq,
            objetivo: "TODOS",
            opcional: false,
            tipo: "ARCHIVO"
        });
    },
    removeLastReqCurrentService(state){
        let index = state.currentTramiteInfo.requisitos.length-1;
        state.currentTramiteInfo.requisitos.splice(index, 1); //remueve un elemento a partir del indice indicado
    },
    updateIsServiceInactivoFromServer(state, status){
        state.isServiceInactivoFromServer = status == 'INACTIVO';
    },
    updateCategorias (state, newCategorias) {
        state.categorias = newCategorias;
    },
    updateCategoriasList (state, newCategorias) {
        state.categoriasList = newCategorias;
    },
    updateCategoriaNueva (state, newCategoria) {
        state.categoriaNueva = newCategoria;
    },
    updatePathname (state) {
        state.pathname = location.pathname.split("/")[2];
    },
}

export default {
    state,
    mutations
}