const state = {
    isWorking: false,
    token:null,
    dateToken:null,
    fileDownload: null,
    addServiceMsgError:"",
    pdfGenerated:null,
    isShowListaTramites:true,
    isShowModalPdfLinea:false,
    requisitoForm:"",
    isShowRequisitoTypeForm:false,
    isShowModalGoLogin: false,
    isShowCardsUser:false,
    isShowSuccesUser:false,
    isShowModalDatosUser:false,
    isShowModalObservaciones:false,
    msjSuccessUser:"",
    msjErrorLogin:{
        msj:"",
        time:0
    },
    msjErrorUser:{
        msj:"",
        time:0,
        isShowMsjInModal:false
    },
    msjErrorRequisitoUser:"",
    isShowNewUser:null,
    isShowModalVerificarCodigo:false,
    categorias: null,
    serviceInfo: null,
    perfil: null,
    currentService:null,
    datosPago:{
        serie:null,
        folio:null,
        total:null,
        correo:null
    },
    isShowModalFirmaElectronica:false,
    isShowModalFirmaElectronicaSimple:false,
    principalColor:'#696969',
    iconsColor: "#696969",
    
    // Variables colors   
    
    
    //themeColor: '#6262E8',
    //gradientColor: 'linear-gradient(90deg, rgba(153,121,241,1) 0%, rgba(104,101,233,1) 100%)',
    gradientColor: 'linear-gradient(90deg, rgb(255, 31, 31) 0%, rgb(168, 0, 0) 100%)'
}

// mutations
const mutations = {
    updateIsShowModalDatosUser(state, newIsShowModalDatosUser){
        state.isShowModalDatosUser = newIsShowModalDatosUser;
    },
    updateMsjSuccessUser(state, newMsjSuccessUser){
        state.msjSuccessUser=newMsjSuccessUser;
        state.isShowSuccesUser = newMsjSuccessUser != "";
    },
    updateIsShowModalGoLogin(state, newIsShowModalGoLogin){
        state.isShowModalGoLogin = newIsShowModalGoLogin;
    },
    updateIsShowCardsUser(state, newIsShowCardsUser){
        state.isShowCardsUser = newIsShowCardsUser;
    },
    updateIsShowListaTramites(state, newIs){
        state.isShowListaTramites=newIs;
    },
    updateIsShowRequisitoTypeForm(state, newIsShowRequisitoTypeForm){
        state.isShowRequisitoTypeForm = newIsShowRequisitoTypeForm;
    },
    updateRequisitoForm(state, newRequisitoForm){
        state.requisitoForm=newRequisitoForm;
    },
    updateIsShowModalPdfLinea(state, newIsShowModalPdfLinea){
        state.isShowModalPdfLinea=newIsShowModalPdfLinea;
    },
    updatePdfGenerated(state, newPdfGenerated){
        state.pdfGenerated = newPdfGenerated;
    },
    updateMsjErrorUser(state, newMsjError){
        state.msjErrorUser.msj=newMsjError.msj;
        state.msjErrorUser.time=newMsjError.time;
        state.msjErrorUser.isShowMsjInModal=newMsjError.isShowMsjInModal;
    },
    updateTimeMsjErrorUser(state, newTime){
        state.msjErrorUser.time = newTime;
    },
    updateIsShowModalVerificarCodigo(state, newIsShowModalVerificarCodigo){
        state.isShowModalVerificarCodigo=newIsShowModalVerificarCodigo;
    },
    updateIsShowModalFirmaElectronica(state, newIs){
        state.isShowModalFirmaElectronica=newIs
    },
    updateIsShowModalFirmaElectronicaSimple(state, newIs){
        state.isShowModalFirmaElectronicaSimple=newIs
    },
    updateMsjErrorRequisitoUser(state, newMsjErrorRequisitoUser){
        state.msjErrorRequisitoUser = newMsjErrorRequisitoUser;
    },
    updateTimeMsjErrorLogin(state, newTime){
        state.msjErrorLogin.time = newTime;
    },
    updateMsjErrorLogin(state, newMsjErrorLogin){
        state.msjErrorLogin.msj=newMsjErrorLogin.msj;
        state.msjErrorLogin.time=newMsjErrorLogin.time;
    },
    updateIsShowNewUser(state, isShowNewUser){
        state.isShowNewUser=isShowNewUser;
    },
    addServiceUser(state, newService){
        state.perfil.services.push({idUserService:newService.idUserService, idServiceInfo:newService.idServiceInfo, nombre:newService.nombre, estado:newService.estado});
    },
    cleanPerfilUser(state){
        state.token = null;
        state.perfil = null;
        state.categorias = null;
        state.serviceInfo = null;
        state.currentService = null;
        state.isShowCardsUser = false;
    },
    updateIsWorking(state, newIsWorking){
        state.isWorking = newIsWorking;
    },
    updateRequisitoInCurrentService(state, newUserRequisito){
        for(var idx=0; state.currentService.requisitos.length > idx; idx++){
            if(state.currentService.requisitos[idx].idRequisito == newUserRequisito.idRequisito){
                state.currentService.requisitos[idx].nombre=newUserRequisito.nombre,
                state.currentService.requisitos[idx].descripcion=newUserRequisito.descripcion,
                state.currentService.requisitos[idx].linkFormato=newUserRequisito.linkFormato,
                state.currentService.requisitos[idx].fecEntregado=newUserRequisito.fecEntregado,
                state.currentService.requisitos[idx].estado=newUserRequisito.estado
                state.currentService.statusReqs = newUserRequisito.estadoRequisitos
            }
        }
    },
    updateHashSolicitudCurrenServiceUser(state, newHash){
        state.currentService.hashSolicitud=newHash
    },
    updateEstadoCurrentServiceUser(state, newEstado){
        state.currentService.estado = newEstado;
        //Se actualiza tambien en el array services
        for(var idx=0; state.perfil.services.length > idx; idx++){
            if(state.currentService.idUserService == state.perfil.services[idx].idUserService){
                state.perfil.services[idx].estado = newEstado;
            }
        }
    },
    updateSolicitudCurrentService(state, newSolicitud){
        state.currentService.solicitud = newSolicitud;
    },
    updateEstadoReqsCurrentService(state, newEstado){
        state.currentService.statusReqs = newEstado
    },
    addUserRequisito(state, newUserRequisito){
        state.currentService.push(newUserRequisito);
    },
    updateToken (state, newToken) {
        state.token = 'Bearer '+newToken;
        state.dateToken = new Date().getTime();
    },
    updatePerfil (state, newPerfil) {
        state.perfil = newPerfil;
    },
    updatePerfilServicios(state, newServices){
        /*Clonamos el objeto original y le asignamos la nueva propiedad services, esto lo hacemos para poder tener reactividad ya que
        si lo asignamos directamente en la propiedad, vue no va a detectar el cambio*/
        state.perfil = Object.assign({}, state.perfil, { services: newServices})
    },
    updateServiceInfo (state, newServiceInfo) {
        state.serviceInfo = newServiceInfo;
    },
    updateCategorias (state, newCategorias) {
        state.categorias = newCategorias;
    },
    updateIdServiceStart (state, newIdServiceStart) {
        state.idServiceStart = newIdServiceStart;
    },
    upadteCurrentService(state, newCurrentService){
        state.currentService = newCurrentService;
    },
    updateFileDownload (state, newFileDownload) {
        state.fileDownload = newFileDownload;
    },
    updateIsShowModalObservaciones(state, newIs){
        state.isShowModalObservaciones = newIs;
    }
}

export default {
    state,
    mutations
}