import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuelidate from "vuelidate"
import store from './store'
import {getTokenToEvaluate} from '@/vue-tools'
import { isTokenUserValid, isTokenAdminValid, isTokenClientValid } from '@/auth-tools'
Vue.use(VueRouter)
Vue.use(Vuelidate)

var router = new VueRouter({
  routes: [
    /*Usuario*/
    {
      path: '/:municipio/loginuser',/*login de usuario */
      component: () => import('@/components/user/loginUser.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/:municipio/tramitesuser',/*Muestra la vista catalogo de tramites de usuario */
      component: () => import('@/components/user/serviceslink.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/:municipio/catalogotramites',/*Muestra la vista catalogo de tramites de usuario */
      component: () => import('@/components/user/servicesCatalog.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/:municipio/tramiteinfo',/*Muestra la vista con la información de un trámite tramiteinfo */
      component: () => import('@/components/user/serviceInfo.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/:municipio/perfiluser',/*Muestra la vista donde el usuario puede ver los tramites en los que esta registrado perfiluser */
      component: () => import('@/components/user/userPanel.vue'),
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/:municipio/logupusuario',/*Muestra la vista donde un usuario se puede registrar logupusuario */
      component: () => import('@/components/user/logupUser.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/:municipio/portalcontribuyente',/*Muestra la vista siprem*/
      component: () => import('@/components/user/panelPago.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    /*Admin*/
    {
      path: '/:municipio/loginadmin',/*login de admin */
      component: () => import('@/components/admin/loginAdmin.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/:municipio/tramitesadmin',/*Muestra la vista catalogo de tramites de administrador */
      component: () => import('@/components/admin/panelTramites.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:municipio/perfiladmin',/*Muestra la vista donde un administrador puede revisar todos los tramites de una categoria perfiladmin */
      component: () => import('@/components/admin/panelAdminTramite.vue'),
      meta: { 
        requiresAuth: true
      }
    },
    /*Client*/
    {
      path: '/:municipio/loginclient',/* login cliente */
      component: () => import('@/components/client/loginClient.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/:municipio/tramitesclient',/* muestra la vista con el catalogo de tramites para clientes */
      component: () => import('@/components/client/panelCatalogoTramites.vue'),
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/:municipio/perfilclient',/* Es la vista que se muestra al seleccionar un tramite */
      component: () => import('@/components/client/panelConfigTramite.vue'),
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/:municipio/paneladmins',/* Es la vista que se muestra para administrar los usuarios administradores */
      component: () => import('@/components/client/panelAdministradores.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:municipio/panelcats',/* Es la vista que se muestra para administrar las categorias */
      component: () => import('@/components/client/panelCategorias.vue'),
      meta: {
        requiresAuth: true
      }
    }
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  if(to.path == '/'+location.pathname.split("/", 2).toString().replace(",", "")+'/'){
    next({
      path: '/'+location.pathname.split("/", 2).toString().replace(",", "")+'/tramitesuser'
    })
  }else{
    if(to.matched.some(record => record.meta.requiresAuth)){
      var tokenToEvaluate = getTokenToEvaluate(location.pathname.split("/")[2]);
      if(tokenToEvaluate=="user"){
        if(isTokenUserValid(store, router)){
          next();
        }
      }else if(tokenToEvaluate=="admin"){
        if(isTokenAdminValid(store, router)){
          next();
        }
      }else if(tokenToEvaluate=="client"){
        if(isTokenClientValid(store, router)){
          next();
        }
      }
    }else{
      next();
    }
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
