function isTokenUserValid(store, router){
    var dateToken = store.state.user.dateToken;
    var token = store.state.user.token;

    if(token == null || dateToken==null){
        router.push('/'+location.pathname.split("/", 2).toString().replace(",", "")+"/loginuser");
        return false;
    }
    var horaActual = new Date().getTime();
    var difMils = horaActual - dateToken;
    var secs = difMils/1000;
    
    //60 secs *60*72  = 72 horas
    if(secs>(60*60*72)){
        store.commit("cleanPerfilUser");
        router.push('/'+location.pathname.split("/", 2).toString().replace(",", "")+"/loginuser");
        store.commit("updateMsjSuccessUser", "La sesión ha terminado. Inicia sesión nuevamente.");
        return false;
    }
    return true;
}

function isTokenAdminValid(store, router){
    var dateToken = store.state.admin.dateToken;
    var token = store.state.admin.token;

    if(token == null || dateToken==null){
        router.push('/'+location.pathname.split("/", 2).toString().replace(",", "")+"/loginadmin");
        return false;
    }
    var horaActual = new Date().getTime();
    var difMils = horaActual - dateToken;
    var secs = difMils/1000;
    
    //60 secs *60*72  = 72 horas
    if(secs>(60*60*72)){
        store.commit("cleanPerfilAdmin");
        router.push('/'+location.pathname.split("/", 2).toString().replace(",", "")+"/loginadmin");        
        store.commit("updateMsjSuccessUser", "La sesión ha terminado. Inicia sesión nuevamente.");
        return false;
    }
    return true;
}

function isTokenClientValid(store, router){
    var dateToken = store.state.client.dateToken;
    var token = store.state.client.token;

    if(token == null || dateToken==null){
        router.push('/'+location.pathname.split("/", 2).toString().replace(",", "")+"/loginclient");
        return false;
    }
    var horaActual = new Date().getTime();
    var difMils = horaActual - dateToken;
    var secs = difMils/1000;
    
    //60 secs *60*72  = 72 horas
    if(secs>(60*60*72)){
        store.commit("cleanPerfilClient");
        router.push('/'+location.pathname.split("/", 2).toString().replace(",", "")+"/loginclient");      
        store.commit("updateMsjSuccessUser", "La sesión ha terminado. Inicia sesión nuevamente.");  
        return false;
    }
    return true;
}

export {isTokenUserValid, isTokenAdminValid, isTokenClientValid};