<template >
  <b-container fluid class="container">
      <loading :active.sync="isWorkingClient" :is-full-page="true"></loading>
    <b-row align-h="center" class="title-logup"><h4>Cambiar contraseña</h4></b-row>
    <b-card class="c-shadow-sm">
        <b-row>
            <b-col md="6" cols="12">
                <b-form-group label="Nueva contraseña">
                    <b-form-input placeholder="Ingrese la nueva contraseña" :state="!$v.passNvo.$invalid" type="password" v-model.trim="$v.passNvo.$model"></b-form-input>
                    <div class="error" v-if="!$v.passNvo.minLength">La contraseña es demasiado corta</div>
                    <div class="error" v-if="!$v.passNvo.maxLength">La contraseña es demasiado larga</div>
                    <div class="error" v-if="!$v.passNvo.required">Ingresa una contraseña con al menos 6 caracteres</div>
                    <br>
                    <b-button @click="guardarCambios" variant="success" :disabled="$v.passNvo.$invalid" >
                        Guardar
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card>
  </b-container>
</template>

<script>
  import { minLength, maxLength, required } from 'vuelidate/lib/validators';
  import {actualizarPassClient } from '@/axios-tools';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { mapState} from 'vuex';

  export default {
    computed: {
      ...mapState({
        token: state => state.client.token,
        isWorkingClient: state => state.client.isWorkingClient
      }),
    },
    components: {
      Loading
    },
    data() {
      return {
        passNvo: ""
      }
    },
    methods: {
        guardarCambios(){
            actualizarPassClient(this.$store, this.passNvo, this.token, this.$router);
        }        
    },
    validations: {
        passNvo:{
            required,
            minLength: minLength(6),
            maxLength: maxLength(50)
        }
    }
  }
</script>
<style>
div.success{
  color: green;
  margin-bottom: 10px;
}
.title-logup{
    margin-bottom:20px;
}
div.error    {color: red;}
</style>