import axios from 'axios';
import { downloadFileToB64, handleErrorUser, handleErrorAdmin, handleErrorClient, generarPdf } from '@/vue-tools';
import { isTokenUserValid, isTokenAdminValid, isTokenClientValid } from '@/auth-tools'

const url = "https://gobinet.appspot.com/"
//const url = "http://192.168.1.65:9001/"
//const url = "http://localhost:9000/"
//const url = "http://192.168.1.65:9000/"
//const url = "https://gobinet-demo.appspot.com/"

function loginUser(store, router, user, pass, cliente, captcha){
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        url: url + 'loginuser',
        params: {
            "curp": user.toUpperCase(),
            "pass": pass,
            "cliente": cliente,
            "captcha": captcha
        }
    })
    .then(res => {
        if(res.data[0].curp!=null && res.data[1] != null){
            store.commit("updatePerfil", res.data[0]);
            store.commit("updateToken", res.data[1]);
            router.push('/' + cliente + '/perfiluser');
        }else{
            store.commit("updateMsjErrorLogin", {msj:"CURP ó Contraseña incorrectos", time:4});
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);  
        store.commit("updateIsWorking", false);
    });
}

function logupUser(store, cliente, registroFormModel, captcha){
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'logupuser',
        params:{
            "codeClient": cliente,
            "captcha": captcha
        },
        data: {
            "nombre": registroFormModel.nombre,
            "apellidoPat": registroFormModel.apellidoPat,
            "apellidoMat": registroFormModel.apellidoMat,
            "curp": registroFormModel.curp.toUpperCase(),
            "correo": registroFormModel.correo,
            "telefono": registroFormModel.telefono,
            "calle": registroFormModel.calle,
            "no": registroFormModel.no,
            "noInt": registroFormModel.noInt,
            "colonia": "SC",
            "delMunicipio": "SM",
            "estado": "SE",
            "cp": registroFormModel.cp,
            "pass": registroFormModel.pass
        }
    })
    .then(res => {
        if(res.status == 200){
            store.commit("updatePerfil", res.data);
            store.commit("updateIsShowModalVerificarCodigo",true);
        }else if(res.status==210){
            store.commit("updateMsjErrorUser", {msj:"El usuario ya existe", time:0, isShowMsjInModal:true});
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function verificaCodigo(store, router, codigoVerificacion, idUser){
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'verificacodigo',
        params: {
            "codigoVerificacion":codigoVerificacion,
            "idUser":idUser
        }
    })
    .then(res => {
        if(res.status== 200){
            store.commit("updateIsShowModalVerificarCodigo",false);
            store.commit("updateIsShowModalGoLogin", true);
        }else if(res.status == 210){
            store.commit("updateIsShowModalVerificarCodigo",false);
            store.commit("updateMsjErrorUser", {msj:"El código ingresado es incorrecto, por favor intenta nuevamente", time:4, isShowMsjInModal:true});
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function updateUser(store, router, cliente, registroFormModel, captcha, idUser, token){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'updateuser',
        headers: {'Authorization': token},
        params:{
            "cliente": cliente,
            "captcha": captcha
        },
        data: {
            "nombre": registroFormModel.nombre,
            "apellidoPat": registroFormModel.apellidoPat,
            "apellidoMat": registroFormModel.apellidoMat,
            "curp": registroFormModel.curp,
            "correo": registroFormModel.correo,
            "telefono": registroFormModel.telefono,
            "calle": registroFormModel.calle,
            "no": registroFormModel.no,
            "noInt": registroFormModel.noInt,
            "colonia": "SC",
            "delMunicipio": "DM",
            "estado": "SE",
            "cp": registroFormModel.cp,
            "pass": registroFormModel.pass,
            "idUser": idUser
        }
    })
    .then(res => {
        store.commit("updateIsWorking", false);        
        if(registroFormModel.pass != null && registroFormModel.pass.trim()!=''){
            store.commit("updateIsShowModalDatosUser", false);
            store.commit("cleanPerfilUser");
            router.push('/' + cliente + '/loginuser');
            store.commit("updateMsjSuccessUser", "Usuario Actualizado. Inicie sesión nuevamente");
        }else{
            store.commit("updatePerfil", res.data);
            store.commit("updateMsjSuccessUser", "Información actualizada exitosamente");
            //router.push('/' + cliente + '/perfiluser');
        }
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}


function tramitesInfoUser(cliente, store){
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        url: url + 'tramitesinfouser',
        params: {
            "cliente": cliente
        }
    })
    .then(res => {
        store.commit("updateCategorias", res.data);
        store.commit("updateIsWorking", false);
        setTimeout(function(){ store.commit("updateIsShowCardsUser", true); }, 500);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}


function serviceInfo(idServiceInfo, cliente, store, router){
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        url: url + 'serviceinfo',
        params: {
            "idServiceInfo": idServiceInfo,
            "cliente": cliente
        }
    })
    .then(res => {
        store.commit("updateServiceInfo", res.data);
        router.push('/' + cliente + '/tramiteinfo');
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function currentService(cliente, token, idUserService, curp, store, router){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        url: url + 'currentserviceuser',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idUserService": idUserService,
            "curp": curp
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible consultar el trámite. El trámite ya se encuentra CANCELADO");
        }else{
            store.commit("upadteCurrentService", res.data[0]);
            store.commit("updatePerfilServicios", res.data[1]);
            if(res.data[0].obsTxt!=null || res.data[0].obsPdf!=null){
                store.commit("updateIsShowModalObservaciones", true);
            }
            store.commit("updateIsShowListaTramites", false);
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function cancelarTramiteUser(cliente, token, idUserService, store, router, motivo){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'cancelartramiteuser',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idUserService": idUserService
        },
        data: {
            "texto" : motivo,
            "nombreArchivo" : null,
            "b64":null,
            "tipo":null
        }
    })
    .then(res => {
        store.commit("updateIsShowListaTramites", true );
        store.commit("upadteCurrentService", null); ///lo ponemos en null para que ya no se vea
        store.commit("updatePerfilServicios", res.data[1]); //actualizamos la lista de tramites
        store.commit("updateMsjSuccessUser", "El trámite ha sido cancelado.");        
        store.commit("updateIsWorking", false);
    }).catch(err => {
        store.commit("updateIsWorking", false);
        handleErrorAdmin(err, store);
    });
}

function addService(cliente, token, idServiceInfo, store, router, tipoPersona){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'addserviceuser',
        headers: {'Authorization': token},
        params: {
            "idServiceInfo": idServiceInfo,
            "tipoPersona":tipoPersona
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible iniciar el trámite. El trámite ya se encuentra dado de baja");
        }else{
            store.commit("upadteCurrentService", res.data);
            store.commit("addServiceUser",res.data);
            router.push('/' + cliente + '/perfiluser');
            store.commit("updateIsShowListaTramites", false);
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function registrarRequisitoUser(cliente, token, idUserService, idRequisito, b64, tipo, store, router){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'registrarrequisitouser',
        headers: {'Authorization': token},
        params: {
            'idUserService': idUserService,
            'idRequisito': idRequisito,
            'cliente': cliente
        },
        data: {
            'b64': b64,
            'tipo': tipo
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible enviar el requisito. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else{
            store.commit("updateMsjSuccessUser", "Requisito enviado exitosamente.");
            store.commit("updateRequisitoInCurrentService", res.data[0]);
            store.commit("updateHashSolicitudCurrenServiceUser", res.data[1]);
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function enviarSolicitudFirma(token, idUserService, firma, id, store, router){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'firmarsolicituduser',
        headers: {'Authorization': token},
        params: {
            'idUserService': idUserService
        },
        data: {
            'firmaSolicitud':firma,
            'identificacion':id
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible enviar la solicitud. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else if(res.status == 230){
            store.commit("updateMsjSuccessUser", res.data);
        }else{
            store.commit("updateMsjSuccessUser", "Solicitud enviada exitosamente.");
            store.commit("updateEstadoCurrentServiceUser", 'POR REVISAR'); //Si fue exitoso significa que el tramite pasa a POR REVISAR
            store.commit("updateEstadoReqsCurrentService", 'POR REVISAR');
            store.commit("updateSolicitudCurrentService", res.data); //actualizar pdf solicitud
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function descargarRequisitoUser(cliente, idUserService, token, idRequisito, store, nombreRequisito, isTipoForm, router){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        url: url + 'descargarrequisitouser',
        headers: {'Authorization': token},
        params: {
            'idUserService': idUserService,
            'cliente': cliente,
            'idRequisito': idRequisito
        }
    })
    .then(res => {
        if(!isTipoForm){
            downloadFileToB64(res.data.b64, res.data.tipo, nombreRequisito);
        }else{
            store.commit("updateRequisitoForm", atob(res.data.b64));
            store.commit("updateIsShowRequisitoTypeForm", true);
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}


function descargarOrdenPagoUser(idUserService, token, store, router){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        url: url + 'descargarordenpagouser',
        headers: {'Authorization': token},
        params: {
            'idUserService': idUserService
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible descargar el Formato. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else if(res.status == 200){
            var pdf = generarPdf(res.data, store);
            var downloadPdf = document.createElement('a');
            downloadPdf.setAttribute('href', pdf);
            downloadPdf.setAttribute('download', "Formato_Pago.pdf");
            downloadPdf.click();
        }else if(res.status == 210){
            store.commit("updateMsjSuccessUser", "Aun no se autoriza la generación del formato. Contacte al administrador del trámite");
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}

function generarUrlPagoUser(idUserService, token, store, router){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        url: url + 'generaurlpago',
        headers: {'Authorization': token},
        params: {
            'idUserService': idUserService
        }
    })
    .then(res => {
        if(res.status == 200){
            if(res.data.includes('http')){
                window.open(res.data, '_blank');
            }else{
                store.commit("updateMsjSuccessUser", res.data);
            }
        }else if(res.status == 210){
            store.commit("updateMsjSuccessUser", "El Servicio de Pago En Línea no esta disponible. Intente más tarde");
        }
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}


function descargarEntregableUser(cliente, idUserService, token, idEntregable, store, nombreEntregable, router){
    if(!isTokenUserValid(store, router)){ return null }
    store.commit("updateIsWorking", true);
    axios({
        method: 'get',
        url: url + 'descargarentregableuser',
        headers: {'Authorization': token},
        params: {
            'idUserService': idUserService,
            'cliente': cliente,
            'idEntregable': idEntregable
        }
    })
    .then(res => {
        if(res.data.tipo!='C'){
            store.commit("updateEstadoCurrentServiceUser", 'CONCLUIDO'); //Si fue exitoso significa que el tramite pasa CONCLUIDO
        }
        downloadFileToB64(res.data.b64, 'pdf', nombreEntregable);
        store.commit("updateIsWorking", false);
    }).catch(err => {
        handleErrorUser(err, store);
        store.commit("updateIsWorking", false);
    });
}


function recuperarContraseñaUser(curp, correo, cliente, store, captcha){
    store.commit("updateIsWorking", true);
    axios({
        method: 'post',
        url: url + 'recuperarcontrasenauser',
        params: {
            "codeClient": cliente,
            "captcha": captcha
        },
        data: {
            "correo": correo,
            "username": curp.toUpperCase()
        }
    })
    .then(res => {
        store.commit("updateIsWorking", false);
        if(res.status == 200){
            store.commit("updateMsjSuccessUser", "Hemos enviado tu nueva contraseña al correo: "+res.data.correo);
        }else if(res.status == 299){
            store.commit("updateMsjSuccessUser", "Los datos ingresados son incorrectos");
        }
    }).catch(err => {
        store.commit("updateIsWorking", false);
        handleErrorUser(err, store);
    });
}

/* ******************************************************************* ADMIN ***************************************************************/

function loginAdmin(store, router, user, pass, cliente, captcha){
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'get',
        url: url + 'loginadmin',
        params: {
            "username": user,
            "pass": pass,
            "cliente": cliente,
            "captcha": captcha
        }
    })
    .then(res => {
        if(res.data[0].username!=null && res.data[1]!=null){
            store.commit("updatePerfilAdmin", res.data[0]);
            store.commit("updateTokenAdmin", res.data[1]);
            router.push('/'+cliente+'/tramitesadmin');
        }else{
            store.commit("updateMsjErrorLoginAdmin", {msj:"Usuario ó Contraseña incorrectos", time:4});
        }
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function tramitesAdmin(cliente, token, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'get',
        url: url + 'tramitesadmin',
        headers: {'Authorization': token},
        params: {
            'cliente': cliente
        }
    })
    .then(res => {
        store.commit("updateCategorias", res.data);
        store.commit("updateIsWorkingAdmin", false);
        setTimeout(function(){ store.commit("updateIsShowCardsAdmin", true); }, 500);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function currentTramitesAdmin(cliente, token, tipo, idServiceInfo, router, store, redirect){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'get',
        url: url + 'currenttramitesadmin',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "tipo": tipo,
            "idServiceInfo": idServiceInfo
        }
    })
    .then(res => {
        store.commit("updatePorRevisarInfo", res.data);
        if(redirect){
            router.push('/' + cliente + '/perfiladmin');
            store.commit("updatePathname", location.pathname.split("/")[2]);
        }
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}


function agregarServicioPPAdmin(token, idServiceInfo, nombre, ap, am, correo, telefono, tipo, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'post',
        url: url + 'addserviceppadmin',
        headers: {'Authorization': token},
        params: {
            "idServiceInfo": idServiceInfo,
            "nombre":nombre,
            "apellidoPat":ap,
            "apellidoMat":am,
            "correo":correo,
            "tel":telefono,
            "tipoPersona":tipo
        }
    })
    .then(res => {
        store.commit("addTramiteToTramitesPorRevisar", res.data);
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}



function currentTramiteAdmin(cliente, token, idUserService, idServiceInfo, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'get',
        url: url + 'currenttramiteadmin',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idUserService": idUserService,
            "idServiceInfo":idServiceInfo
        }
    })
    .then(res => {
        store.commit("updateInfoCurrentTramite", res.data);
        store.commit("updateIsWorkingAdmin", false);
        store.commit("updateIsShowModalConfigTramite", true);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function updateObsReqsAdmin(cliente, token, idUserService, text, b64File, nFile, conFirma, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdminModals", true);
    axios({
        method: 'post',
        url: url + 'updateobsreqsadmin',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idUserService": idUserService,
            "cFirma": conFirma
        },
        data:{
            "texto": text,
            "nombreArchivo": nFile,
            "b64": b64File
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible actualizar el trámite. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else{
            if(conFirma){
                store.commit("updateUrlFirmaAdmin", res.data);
                store.commit("updateIsShowModalFirmaAdmin", true);
            }else{
                store.commit("updateMsjSuccessUser", "Información enviada exitosamente.");
            }            
        }
        store.commit("updateIsWorkingAdminModals", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdminModals", false);
    });
}

function updateEstadoReqAdmin(token, idRequisito, estado, store, isAllReqsAcepted, isAllReqsEmpty, idUserService, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdminModals", true);
    axios({
        method: 'post',
        url: url + 'updateestadoreqadmin',
        headers: {'Authorization': token},
        params: {
            "estado": estado,
            "idRequisito": idRequisito
            
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible actualizar el trámite. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else{
            if(isAllReqsAcepted){
                store.commit("updateEstadoCurrentServiceAdmin", 'PENDIENTE PAGO'); //Si fue exitoso significa que el tramite pasa a PENDIENTE PAGO
                store.commit("deleteServiceFromTramitesResultAdmin", idUserService); //Quitar de la tabla de resultados
            }else if(isAllReqsEmpty){
                store.commit("updateEstadoCurrentServiceAdmin", 'INICIADO'); //Si fue exitoso significa que el tramite pasa a INICIADO
                store.commit("deleteServiceFromTramitesResultAdmin", idUserService); //Quitar de la tabla de resultados
            }
            store.commit("updateMsjSuccessUser", "Operación realizada exitosamente.");
            store.commit("updateRequisitoCurrentTramite", res.data); //Actualizamos el modelo por que aun permitiremos que el admin vea el tramite
        }
        store.commit("updateIsWorkingAdminModals", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdminModals", false);
    });
}

function downloadReqAdmin(cliente, token, idUserService, idRequisito, store, nombreRequisito, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'get',
        url: url + 'downloadreqadmin',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idRequisito": idRequisito,
            "idUserService": idUserService
        }
    })
    .then(res => {
        if(res.data.tipo=='png' || res.data.tipo=='jpg' || res.data.tipo=='pdf' || res.data.tipo=='jpeg'){
            store.commit("updateArchivoDescargado", {b64:res.data.b64, texto:res.data.tipo});
            store.commit("updateIsShowModalViewFile", true);
        }else{
            downloadFileToB64(res.data.b64, res.data.tipo, nombreRequisito);
        }
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function updateOrdenPagoAdmin(token, idUserService, serie, folio, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'post',
        url: url + 'updateordenpagoadmin',
        headers: {'Authorization': token},
        params: {
            "idUserService": idUserService,
            "serie": serie,
            "folio": folio
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible actualizar el formato. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else{
            if(res.data != null && res.data != '' ){
                store.commit("updateOrdenPagoCurrentTramite", res.data);   
                store.commit("updateMsjSuccessUser", "Operación realizada exitosamente.");        
            }else{
                store.commit("updateMsjSuccessUser", "El formato no se actualizo ya que no existe, esta cancelado o ya se encuentra en uso.");
            }
        }        
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function descargarSolicitudAdmin(token, idUserService, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'post',
        url: url + 'descargasolicitudadmin',
        headers: {'Authorization': token},
        params: {
            "idUserService": idUserService,
        }
    })
    .then(res => {
        store.commit("updateIsWorkingAdmin", false);
        store.commit("updateSolicitudAdmin", res.data); 
        store.commit("updateIsShowModalSolicitud", true);       
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function updateObsEntsAdmin(cliente, token, idUserService, text, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'post',
        url: url + 'updateobsentsadmin',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idUserService": idUserService
        },
        data:{
            "texto": text
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible actualizar el trámite. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else{
            store.commit("updateObsEntregable", res.data.texto); 
            store.commit("updateMsjSuccessUser", "Operación realizada exitosamente.");                   
        }
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function descargarEntregableAdmin(cliente, token, idUserService, idEntregable, store, nombreEntregable, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'get',
        url: url + 'descargarentregableadmin',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idEntregable": idEntregable,
            "idUserService": idUserService
        }
    })
    .then(res => {
        downloadFileToB64(res.data.b64, "pdf", nombreEntregable);
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function altaEntregableAdmin(token, idUserService, idEntregable, b64, tipo, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdminModals", true);
    axios({
        method: 'post',
        url: url + 'altaentregableadmin',
        headers: {'Authorization': token},
        params: {
            "idEntregable": idEntregable,
            "idUserService": idUserService
        },
        data:{
            "b64": b64,
            "tipo": tipo
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible actualizar el trámite. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else if(res.status == 230){
            store.commit("updateMsjSuccessUser", "Mensaje del servicio de firmado electrónico: "+res.data.mensaje);
        }else{
            store.commit("updateEntregablesCurrentTramite", res.data);            
            store.commit("updateMsjSuccessUser", "Operación realizada exitosamente.");        
        }
        store.commit("updateIsWorkingAdminModals", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdminModals", false);
    });
}

function eliminaEntregableAdmin(cliente, token, idEntregable, store, idUserService, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'post',
        url: url + 'eliminaentregableadmin',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idEntregable": idEntregable
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible actualizar el trámite. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else if(res.status == 230){
            store.commit("updateMsjSuccessUser", "Mensaje del servicio de firmado electrónico: "+res.data.mensaje);
        }else{
            store.commit("updateEntregablesCurrentTramite", res.data);    
            store.commit("updateMsjSuccessUser", "El documento ha sido eliminado.");                
        }
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function cerrarTramiteAdmin(cliente, token, idUserService, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'get',
        url: url + 'cerrartramiteadmin',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idUserService": idUserService
        }
    })
    .then(res => {
        store.commit("updateInfoCurrentTramite", res.data);
        store.commit("updateMsjSuccessUser", "Operación realizada exitosamente.");        
        store.commit("updateEstadoCurrentServiceAdmin", 'ENTREGADO'); //Si fue exitoso significa que el tramite pasa a entregado
        store.commit("deleteServiceFromTramitesResultAdmin", idUserService); //Quitar de la tabla de resultados si aun existe    
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}


function cancelarTramiteAdmin(cliente, token, idUserService, store, router, motivo){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'post',
        url: url + 'cancelartramiteadmin',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idUserService": idUserService
        },
        data: {
            "texto" : motivo,
            "nombreArchivo" : null,
            "b64":null,
            "tipo":null
        }
    })
    .then(res => {
        store.commit("deleteServiceFromTramitesResultAdmin", idUserService); //Quitar de la tabla de resultados
        store.commit("updateInfoCurrentTramite", res.data); //Se muesta al usuario, pero el formulario deberia estar ya inactivo por que le estado es CANCELADO
        store.commit("updateMsjSuccessUser", "El trámite ha sido cancelado.");        
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        store.commit("updateIsWorkingAdmin", false);
        handleErrorAdmin(err, store);
    });
}


function notificarCambioAdmin(cliente, token, idUserService, tipo, store, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'post',
        url: url + 'notificarcambios',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "idUserService": idUserService,
            "tipoNotificacion": tipo
        }
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible notificar al usuario. El trámite ya se encuentra CANCELADO o CONCLUIDO");
        }else{
            if(tipo=='D'){//Notificar entregables
                store.commit("updateEstadoCurrentServiceAdmin", 'CONCLUIDO'); //Si fue exitoso significa que el tramite pasa a Concluido
                store.commit("updateEstadoEntregablesCurrentTramite", 'LIBERADO'); //Si fue exitoso significa que el tramite pasa a POR ENTREGAR                
                store.commit("deleteServiceFromTramitesResultAdmin", idUserService); //Quitar de la tabla de resultados 
            }
            store.commit("updateMsjSuccessUser", res.data);
        }
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function buscarTramitesContrib(store, cliente, token, curp, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'get',
        url: url + 'buscartramitescontrib',
        headers: {'Authorization': token},
        params: {
            "cliente": cliente,
            "curp": curp.toUpperCase()
        }
    })
    .then(res => {
        store.commit("updateTramitesPorRevisar", res.data);
        store.commit("updateIsWorkingAdmin", false);
        store.commit("updateIsShowModalTableTramites", true);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function actualizarPassAdmin(store, nPass, token, router){
    if(!isTokenAdminValid(store, router)){ return null }
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'post',
        url: url + 'cambiacontrasenaadmin',
        headers: {'Authorization': token},
        params:{
            "nPass": nPass
        }
    })
    .then(() => {
        store.commit("updateMsjSuccessUser", "La contraseña se ha actualizado correctamente.");        
        store.commit("updateIsShowModalEditPassAdmin", false);
        store.commit("updateIsWorkingAdmin", false);
    }).catch(err => {
        handleErrorAdmin(err, store);
        store.commit("updateIsWorkingAdmin", false);
    });
}

function recuperarContraseñaAdmin(username, correo, cliente, store, captcha){
    store.commit("updateIsWorkingAdmin", true);
    axios({
        method: 'post',
        url: url + 'recuperarcontrasenaadmin',
        params: {
            "codeClient": cliente,
            "captcha": captcha
        },
        data: {
            "correo": correo,
            "username": username.toUpperCase()
        }
    })
    .then(res => {
        store.commit("updateIsWorkingAdmin", false);
        if(res.status == 200){
            store.commit("updateMsjSuccessUser", "Hemos enviado tu nueva contraseña al correo: "+res.data.correo);
        }else if(res.status == 299){
            store.commit("updateMsjSuccessUser", "Los datos ingresados son incorrectos");
        }
    }).catch(err => {
        store.commit("updateIsWorkingAdmin", false);
        handleErrorAdmin(err, store);
    });
}



/***********************************************************************Client*************************************************************** */

function actualizarInfoAdmin(store, modelAdminConfig, token, router){
    if(!isTokenClientValid(store, router)){ return null }
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'post',
        url: url + 'actualizaadmin',
        headers: {'Authorization': token},
        data:{
            "idAdmin": modelAdminConfig.idAdmin,
            "username": modelAdminConfig.username,
            "nombre": modelAdminConfig.nombre,
            "curp": modelAdminConfig.curp,
            "status": modelAdminConfig.status,
            "pass": modelAdminConfig.pass,
            "correo": modelAdminConfig.correo,
            "telefono": modelAdminConfig.telefono
        }
    })
    .then(res => {
        store.commit("updateAdministradores", res.data);
        store.commit("updateMsjSuccessUser", "La información se ha actualizado correctamente.");        
        store.commit("updateIsWorkingClient", false);
    }).catch(err => {
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}

function actualizarPassClient(store, nPass, token, router){
    if(!isTokenClientValid(store, router)){ return null }
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'post',
        url: url + 'cambiacontrasenaclient',
        headers: {'Authorization': token},
        params:{
            "nPass": nPass
        }
    })
    .then(() => {
        store.commit("updateMsjSuccessUser", "La contraseña se ha actualizado correctamente.");        
        store.commit("updateIsShowModalEditPassClient", false);
        store.commit("updateIsWorkingClient", false);
    }).catch(err => {
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}

function buscaAdministradoresClient(store, router, codeClient, token){
    if(!isTokenClientValid(store, router)){ return null }
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'get',
        url: url + 'administradoresclient',
        headers: {'Authorization': token},
    })
    .then(res => {
        store.commit("updateAdministradores", res.data);
        router.push('/' + codeClient + '/paneladmins');
        store.commit("updateIsWorkingClient", false);
    }).catch(err => {
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}

function loginClient(store, router, user, pass, cliente, captcha){
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'get',
        url: url + 'loginclient',
        params: {
            "codigo": user,
            "pass": pass,
            "cliente": cliente,
            "captcha": captcha
        }
    })
    .then(res => {
        if(res.data[0].nombre!=null && res.data[1]!=null){
            store.commit("updatePerfilClient",res.data[0]);
            store.commit("updateTokenClient", res.data[1]);
            router.push('/'+cliente+'/tramitesclient');
        }else{
            store.commit("updateMsjErrorLoginClient", {msj:"Usuario ó Contraseña incorrectos", time:4});
        }
        store.commit("updateIsWorkingClient", false);
    }).catch(err => {
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}


function tramitesClient(token, store, router){
    if(!isTokenClientValid(store, router)){ return null }
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'get',
        url: url + 'tramitesclient',
        headers: {'Authorization': token},
    })
    .then(res => {
        store.commit("updateCategorias", res.data);
        store.commit("updateIsWorkingClient", false);
        setTimeout(function(){ store.commit("updateIsShowCardsClient", true); }, 500);
    }).catch(err => {
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}


function infoTramiteClient(cliente, token, idServiceInfo, store, router){
    if(!isTokenClientValid(store, router)){ return null }
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'get',
        url: url + (idServiceInfo!=null ? 'infotramiteclient' : 'infonuevotramiteclient'),
        headers: {'Authorization': token},
        params: {
            "idServiceInfo": idServiceInfo
        }
    })
    .then(res => {
        store.commit("updateIsWorkingClient", false);
        store.commit("updateCurrentTramiteInfo", res.data);
        store.commit("updateIsServiceInactivoFromServer", res.data.status); 
        store.commit("updateIsEditionMode", idServiceInfo!=null);
        router.push('/' + cliente + '/perfilclient');
        store.commit("updatePathname", location.pathname.split("/")[2]);
    }).catch(err => {
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}

    
function updateSaveInfoTramiteClient(token, data, store, isNew, router){
    if(!isTokenClientValid(store, router)){ return null }
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'post',
        url: url + (isNew? 'savenvoinfotramiteclient' : 'updateinfotramiteclient'),
        headers: {'Authorization': token},
        data
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible guardar el trámite. Ya existe otro con el mismo nombre.");
        }else{
            store.commit("updateCurrentTramiteInfo", res.data);
            store.commit("updateIsServiceInactivoFromServer", res.data.status); 
            store.commit("updateIsEditionMode", true);  //Pasamos a modo edicion  
            store.commit("updateMsjSuccessUser", "Operación realizada exitosamente.");            
        }
        store.commit("updateIsWorkingClient", false);
    }).catch(err => {
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}

function updateSaveCategoriaClient(token, data, store, router){ //data is categoria model
    if(!isTokenClientValid(store, router)){ return null }
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'post',
        url: url + ('saveupdatecategoriaclient'),
        headers: {'Authorization': token},
        data
    })
    .then(res => {
        if(res.status == 220){
            store.commit("updateMsjSuccessUser", "No es posible agregar la categoria. Ya existe otra con el mismo nombre.");
        }else if(res.status == 230){
            store.commit("updateMsjSuccessUser", "No es posible deshabilitar la categoria. La categoria tiene trámites activos.");
        }else{
            store.commit("updateCategoriasList", res.data); 
            store.commit("updateMsjSuccessUser", "Operación realizada exitosamente.");            
        }
        store.commit("clearCategoriaNueva");
        store.commit("updateIsWorkingClient", false);
    }).catch(err => {
        store.commit("clearCategoriaNueva");
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}

function categoriasClient(token, store, router, codeClient){
    if(!isTokenClientValid(store, router)){ return null }
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'get',
        url: url + 'categoriasclient',
        headers: {'Authorization': token},
    })
    .then(res => {
        store.commit("updateCategoriasList", res.data);
        router.push('/' + codeClient + '/panelcats');
        store.commit("updateIsWorkingClient", false);
    }).catch(err => {
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}


function importatramitesClient(token, stringXml, store, router){
    if(!isTokenClientValid(store, router)){ return null }
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'post',
        url: url + 'importatramitesclient',
        headers: {'Authorization': token},
        data:{
            "texto": stringXml
        }
    })
    .then(res => {
        alert(res.data);
        store.commit("updateMsjSuccessUser", "Operación realizada exitosamente.");            
        store.commit("updateIsWorkingClient", false);
    }).catch(err => {
        handleErrorClient(err, store);
        store.commit("updateIsWorkingClient", false);
    });
}

function recuperarContraseñaClient(correo, cliente, store, captcha){
    store.commit("updateIsWorkingClient", true);
    axios({
        method: 'post',
        url: url + 'recuperarcontrasenaclient',
        params: {
            "codeClient": cliente,
            "captcha": captcha
        },
        data: {
            "correo": correo
        }
    })
    .then(res => {
        store.commit("updateIsWorkingClient", false);
        if(res.status == 200){
            store.commit("updateMsjSuccessUser", "Hemos enviado tu nueva contraseña al correo: "+res.data.correo);
        }else if(res.status == 299){
            store.commit("updateMsjSuccessUser", "Los datos ingresados son incorrectos");
        }
    }).catch(err => {
        store.commit("updateIsWorkingClient", false);
        handleErrorClient(err, store);
    });
}

export { loginUser, tramitesInfoUser, serviceInfo, currentService, addService, loginAdmin, buscarTramitesContrib, currentTramitesAdmin, 
    tramitesAdmin, currentTramiteAdmin, updateEstadoReqAdmin, descargarSolicitudAdmin, downloadReqAdmin, updateOrdenPagoAdmin, altaEntregableAdmin, 
    eliminaEntregableAdmin, descargarEntregableAdmin, agregarServicioPPAdmin, cerrarTramiteAdmin, cancelarTramiteAdmin, actualizarPassAdmin, loginClient, logupUser, 
    descargarRequisitoUser, registrarRequisitoUser, enviarSolicitudFirma, descargarOrdenPagoUser, generarUrlPagoUser, descargarEntregableUser, updateObsReqsAdmin, updateObsEntsAdmin,
    notificarCambioAdmin, tramitesClient, infoTramiteClient, recuperarContraseñaUser, recuperarContraseñaAdmin, recuperarContraseñaClient,
    updateUser, verificaCodigo, buscaAdministradoresClient, actualizarInfoAdmin, importatramitesClient, updateSaveInfoTramiteClient,
    categoriasClient, updateSaveCategoriaClient, actualizarPassClient, cancelarTramiteUser}