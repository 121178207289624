import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import admin from './modules/admin'
import client from './modules/client'
import moduloBidireccional from './modules/moduloBidireccional'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    admin,
    client,
    moduloBidireccional
  }
})