<template>
    <div id="app">
      <div class="container-head" v-if="this.$router.currentRoute.path != '/' + this.$route.params.municipio + '/portalcontribuyente' && !isRutaClient">
        <div class="bg-head" :style="{ background:gradientColor }" style="position:absolute;"> <!-- posicion absoluta para que se vaya al fondo-->
          </div>
      </div> 
        

      <!--
      <b-row no-gutters v-if="this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/catalogotramites'">
        <b-col style="margin-top: -20px; margin-bottom:60px;">
          <div>
            <b-carousel :interval="2000" controls indicators background="#ababab" img-height="200" style="text-shadow: 1px 1px 2px #333;">
              <b-carousel-slide v-if="this.$route.params.municipio=='MC080'" :img-src="require('../public/imgs/acolman/1.jpg')"></b-carousel-slide>
              <b-carousel-slide v-if="this.$route.params.municipio=='MC080'" :img-src="require('../public/imgs/acolman/2.jpg')"></b-carousel-slide>
              <b-carousel-slide v-if="this.$route.params.municipio=='MC080'" :img-src="require('../public/imgs/acolman/3.jpg')"></b-carousel-slide>
              <b-carousel-slide v-if="this.$route.params.municipio=='MC080'" :img-src="require('../public/imgs/acolman/4.jpg')"></b-carousel-slide>
              <b-carousel-slide v-if="this.$route.params.municipio=='MC002'" :img-src="require('../public/imgs/coyotepec/1.png')"></b-carousel-slide>
            </b-carousel>
          </div>
        </b-col>
      </b-row>
      -->
      <b-row align-h="center" v-if="isRutaUser">
        <b-col xl="8" md="10" sm="11" cols="11">
          <b-row   >
            <b-col >
              <b-navbar  toggleable="md" type="dark" >   
                <b-navbar-brand href="#"><img  height="55px;" :src="'/imgs/logosmpios/'+codeClient+'.png'" /></b-navbar-brand>       
                <b-navbar-toggle  target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav  align="right" >                          
                  <!-- Right aligned nav items  -->
                  <b-navbar-nav class="ml-auto" >          
                    <b-nav-item style="margin-left:20px;" right v-if="this.$router.currentRoute.path != '/' + this.$route.params.municipio + '/catalogotramites' " @click="goToCatalogo">
                        <b-icon icon="newspaper"></b-icon> <span> Catálogo Trámites </span>
                    </b-nav-item>
                    <b-nav-item style="margin-left:20px;" right v-if="perfil!=null && (this.$router.currentRoute.path != '/' + this.$route.params.municipio + '/perfiluser' || !isShowListaTramites)" @click="goToPerfil">
                        <b-icon icon="archive"></b-icon> <span> Mis Trámites </span>
                    </b-nav-item>
                    <b-nav-item style="margin-left:20px;" right v-if="perfil==null && this.$router.currentRoute.path != '/' + this.$route.params.municipio + '/loginuser'" @click="goToLogin">
                        <b-icon icon="person-circle"></b-icon> <span> Iniciar Sesión </span>
                    </b-nav-item>
                    <b-nav-item-dropdown style="margin-left:20px;" right v-if="perfil!=null">
                      <template #button-content>
                        <b-icon icon="person-circle"></b-icon>
                        <span style="text-transform:capitalize;"> {{perfil.nombre.toLowerCase()}}</span>
                      </template>
                      <b-dropdown-item href="#" @click="showDatosUser"> Mi Cuenta</b-dropdown-item>
                      <b-dropdown-item href="#" @click="limpiarPerfilUser"> Cerrar Sesión</b-dropdown-item>
                    </b-nav-item-dropdown>
                  </b-navbar-nav>
                </b-collapse>
              </b-navbar>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row align-h="center" v-if="isRutaAdmin">
        <b-col xl="8" md="10" sm="11" cols="11">
          <b-row   >
            <b-col >
              <b-navbar  toggleable="md" type="dark" >   
                <b-navbar-brand href="#"><img  height="35px;" :src="'/imgs/logosmpios/'+codeClient+'.png'" /></b-navbar-brand>       
                <b-navbar-toggle  target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav  align="right" >                          
                  <!-- Right aligned nav items  -->
                  <b-navbar-nav class="ml-auto" >          
                    <b-nav-item style="margin-left:20px;" right v-if="perfilAdmin!=null && (this.$router.currentRoute.path != '/' + this.$route.params.municipio + '/tramitesadmin' )" @click="goToTramitesAdmin">
                        <b-icon icon="newspaper"></b-icon> <span> Mis Trámites </span>
                    </b-nav-item>
                    <b-nav-item style="margin-left:20px;" right v-if="perfilAdmin==null && this.$router.currentRoute.path != '/' + this.$route.params.municipio + '/loginadmin'" @click="goToLoginAdmin">
                        <b-icon icon="person-circle"></b-icon> <span> Iniciar Sesión </span>
                    </b-nav-item>
                    <b-nav-item-dropdown style="margin-left:20px;" right v-if="perfil!=null">
                      <template #button-content>
                        <b-icon icon="person-circle"></b-icon>
                        <span style="text-transform:capitalize;"> {{perfil.nombre.toLowerCase()}}</span>
                      </template>
                      <b-dropdown-item href="#" @click="showDatosUser"> Mi Cuenta</b-dropdown-item>
                      <b-dropdown-item href="#" @click="limpiarPerfilUser"> Cerrar Sesión</b-dropdown-item>
                    </b-nav-item-dropdown>
                  </b-navbar-nav>
                </b-collapse>
              </b-navbar>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      
      <router-view style="min-height:780px;"/>
      <br><br>

      <!-- <div v-if="(this.$router.currentRoute.path == '/' + this.$route.params.municipio + '/catalogotramites'  & isShowCardsUser) || this.$router.currentRoute.path != '/' + this.$route.params.municipio + '/catalogotramites' " :style="{ background:gradientColor }" style="min-height:80px; border-top-left-radius: 70px; border-top-right-radius: 70px; ">
        <b-row align-h="center" align="center" align-v="center">
          <b-col cols="12" >
              <b-row align-h="center" align-v="center">
                <b-col cols="12"><br></b-col>
                <b-col cols="12" sm="4" ><h6 style="color:white;"><b-icon icon="file-earmark-check-fill"/> Términos y Condiciones</h6><br></b-col>
                <b-col cols="12" sm="4"><h6 style="color:white;"><b-icon icon="shield-lock-fill"/> Aviso de Privacidad</h6><br></b-col>
                <b-col cols="12" sm="4"><h6 style="color:white;"><b-icon icon="telephone-fill"/> Soporte Técnico</h6><br></b-col>
              </b-row>
          </b-col>
        </b-row>
      </div> -->
      
      <!--modals-->
      <b-modal v-model="isShowModalDatosUser" size="lg" hide-footer>
        <updateUser/>
      </b-modal>
      <b-modal v-model="isShowModalEditPassClient" size="lg" hide-footer>
        <updatePassClient/>
      </b-modal>
      <b-modal v-model="isShowModalEditPassAdmin" size="lg" hide-footer>
        <updatePassAdmin/>
      </b-modal>
      <!-- usado para mostrar mensajes de exito al user, admin y client-->
      <b-modal  hide-header v-model="isShowSuccessUser" size="lg" hide-footer>
        <h5 :style="'color:'+principalColor+';'">{{msjSuccessUser}}</h5>
      </b-modal>
      
      <b-modal  hide-header v-model="msjErrorUser.isShowMsjInModal" size="lg" hide-footer @hide="refresPage">
        <h5 class="danger">{{msjErrorUser.msj}}</h5>
      </b-modal>

      <b-modal  hide-header v-model="msjErrorAdmin.isShowMsjInModal" size="lg" hide-footer @hide="refresPage">
        <h5 class="danger">{{msjErrorAdmin.msj}}</h5>
      </b-modal>

      <b-modal  hide-header v-model="msjErrorClient.isShowMsjInModal" size="lg" hide-footer @hide="refresPage">
        <h5 class="danger">{{msjErrorClient.msj}}</h5>
      </b-modal>


    </div>
    
</template>

<script>
import { mapState} from 'vuex';
import updateUser from './components/user/updateUser.vue';
import updatePassClient from './components/client/updatePassClient.vue';
import updatePassAdmin from './components/admin/updatePassAdmin.vue';
import Vue from 'vue';
  import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)

export default {
  name: 'app',
  components: {
    updateUser,
    updatePassClient,
    updatePassAdmin
  },
  data() {
    return {
      width:window.innerWidth
    }
  },
  computed: {
    ...mapState({
        isShowListaTramites: state => state.user.isShowListaTramites,
        isShowCardsUser: state => state.user.isShowCardsUser,
        principalColor: state => state.user.principalColor,
        gradientColor: state => state.user.gradientColor,
      }),
    codeClient:function(){
      return this.$route.params.municipio;
    },
    isShowListaTramites: {
      get () { return this.$store.state.user.isShowListaTramites },
      set (value) { this.$store.commit("updateIsShowListaTramites", value) }
    },
    isShowModalDatosUser:{
      get: function() {
        return this.$store.state.user.isShowModalDatosUser;
      },
      set: function(newIsShowModalDatosUser) {
        this.$store.commit("updateIsShowModalDatosUser", newIsShowModalDatosUser);
      }
    },
    isShowModalEditPassClient:{
      get: function() {
        return this.$store.state.client.isShowModalEditPassClient;
      },
      set: function(newIsShowModal) {
        this.$store.commit("updateIsShowModalEditPassClient", newIsShowModal);
      }
    },
    isShowModalEditPassAdmin:{
      get: function() {
        return this.$store.state.admin.isShowModalEditPassAdmin;
      },
      set: function(newIsShowModal) {
        this.$store.commit("updateIsShowModalEditPassAdmin", newIsShowModal);
      }
    },
    isShowSuccessUser:{
      get: function() {
        return this.$store.state.user.isShowSuccesUser;
      },
      set: function(newMsjSuccessUser) {
        this.$store.commit("updateMsjSuccessUser", (newMsjSuccessUser == false ? "" : "msg"));
      }
    },
    msjSuccessUser:{
      get: function() {
        return this.$store.state.user.msjSuccessUser;
      },
      set: function(newMsjSuccessUser) {
        this.$store.commit("updateMsjSuccessUser", newMsjSuccessUser);
      }
    },
    msjErrorUser: {
      get: function() {
        return this.$store.state.user.msjErrorUser;
      },
      set: function(newMsjError) {
        this.$store.commit("updateMsjErrorUser", {msj:newMsjError.msj, time:newMsjError.time, isShowMsjInModal:newMsjError.isShowMsjInModal});
      }
    },
    msjErrorAdmin: {
      get: function() {
        return this.$store.state.admin.msjErrorAdmin;
      },
      set: function(newMsjError) {
        this.$store.commit("updateMsjErrorAdmin", {msj:newMsjError.msj, time:newMsjError.time, isShowMsjInModal:newMsjError.isShowMsjInModal});
      }
    },
    msjErrorClient: {
      get: function() {
        return this.$store.state.client.msjErrorClient;
      },
      set: function(newMsjError) {
        this.$store.commit("updateMsjErrorClient", {msj:newMsjError.msj, time:newMsjError.time, isShowMsjInModal:newMsjError.isShowMsjInModal});
      }
    },    
    isShowNavbar(){
      return this.$router.currentRoute.path!='/'+this.$route.params.municipio+'/loginclient'&& this.$router.currentRoute.path!='/'+this.$route.params.municipio+'/loginadmin';
    },
    isRutaNoCatalogoUsuario(){
      return this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/tramiteinfo' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/loginuser' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/logupusuario' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/perfiluser' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/portalcontribuyente';
    },
    isRutaCatalogoUsuario(){
      return this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/catalogotramites' ;
    },    
    isRutaCatalogoAdmin(){
      return this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/tramitesadmin';
    },
    isRutaNoCatalogoAdmin(){
      return this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/perfiladmin';
    },
    isRutaNoCatalogoClient(){
      return this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/perfilclient' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/paneladmins' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/panelcats';
    },
    isRutaCatalogoClient(){
      return this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/tramitesclient';
    },
    isRutaClient(){
      return this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/perfilclient' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/tramitesclient' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/paneladmins' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/panelcats' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/loginclient';
    },
    isRutaUser(){
      return this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/tramiteinfo' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/catalogotramites' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/logupusuario' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/loginuser' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/perfiluser' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/portalcontribuyente';
    },
    isRutaAdmin(){
      return this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/loginadmin' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/tramitesadmin' || this.$router.currentRoute.path=='/'+this.$route.params.municipio+'/perfiladmin';
    },
    tokenUser () {
      return this.$store.state.user.token
    },
    perfil () {
      return this.$store.state.user.perfil
    },
    perfilAdmin () {
      return this.$store.state.admin.perfilAdmin
    },
    tokenAdmin() {
      return this.$store.state.admin.token;
    },
    perfilClient () {
      return this.$store.state.client.perfilClient
    },
    tokenClient () {
      return this.$store.state.client.token
    },
  },
  methods:{
    refresPage(){
      location.reload();
    },
    limpiarPerfilClient(){
      this.$router.push('/'+this.$route.params.municipio+'/loginclient');
      this.$store.commit("cleanPerfilClient");
    },
    limpiarPerfilAdmin(){
      this.$router.push('/'+this.$route.params.municipio+'/loginadmin');
      this.$store.commit("cleanPerfilAdmin");
    },
    limpiarPerfilUser(){
      this.$store.commit("cleanPerfilUser");
      this.$router.push('/'+this.$route.params.municipio+'/loginuser');
    },
    goToLogin(){
      this.$router.push('/'+this.$route.params.municipio+'/loginuser')
    },
    goToPerfil(){
      if(this.$router.currentRoute.path != '/' + this.$route.params.municipio + '/perfiluser' ){
        this.$router.push('/'+this.$route.params.municipio+'/perfiluser')
      }
      this.$store.commit("updateIsShowListaTramites", true);
    },
    goToCatalogo(){
      this.$router.push('/'+this.$route.params.municipio+'/catalogotramites')
    },
    showDatosUser(){
      //this.$store.commit("updateIsShowNewUser", false);
      this.$store.commit("updateIsShowModalDatosUser", true);
    },
    goToTramitesAdmin(){
      this.$router.push('/'+this.$route.params.municipio+'/tramitesadmin')
    },
    goToLoginAdmin(){
      this.$router.push('/'+this.$route.params.municipio+'/loginadmin')
    },

  }
}
</script>

<style>
.iniciado{
  color:#126EE5;
}
.por-revisar{
  color:#099200;
}
.pendiente-pago{
  color:#E05F00;
}
.pagado{
  color:#00BEE4;
}
.por-entregar{
  color:#9834eb;
}
.concluido{
  color:#91673f;
}
.cancelado{
  color:#FF0000;
}
.c-shadow-sm{
  background-color:#FFFFFF;
  box-shadow: 5px 5px 20px -2px #5f5f5f;
  margin-bottom:10px;
  border-radius: 20px;
}
.bg-head-modal {
    width:100%;
    height: 260px;
    border-radius: 0 0 80px 80px;
    /* background: linear-gradient(90deg, rgba(153,121,241,1) 0%, rgba(104,101,233,1) 100%); */
    margin-top:-50px;
  }
.border-login {
  margin-top:20px;
  background-color: #FFFFFF;
  box-shadow: 5px 5px 20px -2px #5f5f5f;
  border-radius: 20px;
}
.button-mis-tramites-sm{
  float:right;
}
.button-mis-tramites{
  margin-right:15px;
}
.success{
  color:green;
}
.danger{
  color:red;
}
.text-md{
  font-size:80%;
}
.text-right{
  text-align: right;
}
.nombre-navbar{
  font-size: 19px;
}
.text-nav:hover{
  color:#FFFFFF;
  font-weight: bold;
}
.text-nav{
  color:#FFFFFF;
  font-weight: bold;
}
.title-page{
  font-weight: bold;
  margin-top:15px;
  font-size:22px;
  color:#A9A9A9;
}
.title-page a{ 
  color:#A9A9A9; 
}
.title-service{
  font-weight: bold;
  font-size:20px;
  color:white;
  text-transform: capitalize;
}
.icon{
  max-width:35px;
  max-height:33px;
}
.icon-in-title{
  max-width:40px;
}
.text-area{
  margin-top: 10px;
}
.bg-navbar {
  /*background-color:rgba(3,55,88,.8);*/
  background-color:rgba(36,0,82,.7);
  box-shadow: 10px 4px 5px 5px #A4A3A3;
}
.nav-item{
  font-weight: bold;
  color:#FFFFFF;
}
.nav-item:hover{
  font-weight: bold;
  color:#FFFFFF;
}
.margin-items-navbar{
  margin-top:-5px;
  margin-bottom:-5px;
}
/* Estilos usados para la transición horizontal (Titulos) */
.slide-fade-enter-active {
  transition: all 0.9s ease;
}
.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateY(100px);
  opacity: 0;
}

/* Estilos usados para la transición vertical (v-cards e imagenes) */
.slide-vertical-enter-active {
  transition: all 0.9s ease;
}
.slide-vertical-leave-active {
  transition: all 0.9s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-vertical-enter, .slide-vertical-leave-to{
  transform: translateX(-70px);
  opacity: 0;
}
.container-head {
  overflow: hidden;
}

@media all and (min-width:901px) {
  .bg-head {
    width:100%;
    height: 400px;
    border-radius: 0 0 80% 80%;
    /* background: linear-gradient(90deg, rgba(153,121,241,1) 0%, rgba(104,101,233,1) 100%); */
    margin-top:-50px;
  }
}

@media all and (min-width:1px) and (max-width: 900px) {
  .bg-head {
    width:100%;
    height: 400px;
    border-radius: 0 0 80px 80px;
    /* background: linear-gradient(90deg, rgba(153,121,241,1) 0%, rgba(104,101,233,1) 100%); */
    margin-top:-50px;
  }
}

.btn-back-purple{
  /* background: linear-gradient(90deg, rgba(153,121,241,1) 0%, rgba(104,101,233,1) 100%); */
  border: none;
}

/*Para que se oculten o muestren iconos dependiente el estado del collapse*/
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.conector-step-active{
  background-color:#28A745; 
  max-width:3px ; 
  margin-left:36px; 
  margin-top:-5px; 
  margin-bottom:-13px; 
  padding:0px;
}
.conector-step-inactive{
  background-color:gray; 
  max-width:3px ; 
  margin-left:36px; 
  margin-top:-2px; 
  margin-bottom:-13px; 
  padding:0px;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Buscar';
}
</style>