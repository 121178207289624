const state = {
    token:null,
    dateToken: null,
    isWorkingAdmin: false,
    isWorkingAdminModals:false,
    isShowCardsAdmin:false,
    isBusquedaByCurp:false,
    isShowModalTableTramites:false,
    filesB64:[],
    archivoDescargado:{b64:null, texto:null, tipo:null},
    solicitudAdmin:null,
    isShowModalViewFile:false,
    isShowModalSolicitud:false,
    isShowModalConfigTramite: false,
    isShowModalEditPassAdmin:false,
    perfilAdmin:null,
    msjErrorLoginAdmin:{
        msj:"",
        time:0
    },
    msjErrorAdmin:{
        msj:"",
        time:0,
        isShowMsjInModal:false
    },
    categorias: null,
    porRevisarInfo:{
        tramites:null
    },
    serie:"",
    folio:"",
    infoCurrentTramite:null,
    urlFirmaAdmin:null,
    isShowModalFirmaAdmin:false
}

// mutations
const mutations = {
    updateIsShowModalTableTramites(state, newIsShowModalTableTramites){
        state.isShowModalTableTramites = newIsShowModalTableTramites;
    },
    updateIsWorkingAdminModals(state, newIsWorkingAdminModals){
        state.isWorkingAdminModals = newIsWorkingAdminModals;
    },
    updateIsShowCardsAdmin(state, newIsShowCardsAdmin){
        state.isShowCardsAdmin = newIsShowCardsAdmin;
    },
    updateIsShowModalEditPassAdmin(state, newEstado){
        state.isShowModalEditPassAdmin = newEstado;
    },
    updateEstadoCurrentServiceAdmin(state, newEstado){
        state.infoCurrentTramite.estado = newEstado;
    },
    updateSerie(state, newSerie){
        state.serie=newSerie;
    },
    updateFolio(state, newFolio){
        state.folio=newFolio;
    },
    updateMsjErrorAdmin(state, newMsjError){
        state.msjErrorAdmin.msj=newMsjError.msj;
        state.msjErrorAdmin.time=newMsjError.time;
        state.msjErrorAdmin.isShowMsjInModal=newMsjError.isShowMsjInModal;
    },
    updateTimeMsjErrorAdmin(state, newTime){
        state.msjErrorAdmin.time = newTime;
    },
    updateTimeMsjErrorLoginAdmin(state, newTime){
        state.msjErrorLogin.time = newTime;
    },
    updateMsjErrorLoginAdmin(state, newMsjErrorLogin){
        state.msjErrorLoginAdmin.msj=newMsjErrorLogin.msj;
        state.msjErrorLoginAdmin.time=newMsjErrorLogin.time;
    },
    updateIsShowModalConfigTramite(state, newIsShowModalConfigTramite){
        state.isShowModalConfigTramite = newIsShowModalConfigTramite;
    },
    updateIsShowModalViewFile(state, newIsShowModalViewFile){
        state.isShowModalViewFile = newIsShowModalViewFile;
    },
    updateIsShowModalSolicitud(state, newIsShow){
        state.isShowModalSolicitud = newIsShow;
    },
    updateArchivoDescargado(state, newArchivoDescargado){
        state.archivoDescargado.b64 = newArchivoDescargado.b64;
        state.archivoDescargado.texto = newArchivoDescargado.texto;
        if(newArchivoDescargado.texto == 'pdf' || newArchivoDescargado.texto == 'xlsx'){
            state.archivoDescargado.tipo = "application";
        }else{
            state.archivoDescargado.tipo = "image";
        }
    },
    updateIsBusquedaByCurp(state, newIsBusquedaByCurp){
        state.isBusquedaByCurp=newIsBusquedaByCurp;
    },
    cleanPerfilAdmin(state){
        state.perfil=null;
        state.token=null;
        state.categorias=null;
        state.porRevisarInfo=null;
        state.isShowCardsAdmin=false;
        state.infoCurrentTramite=null
    },
    updatePerfilAdmin(state, newPerfilAdmin){
        state.perfilAdmin=newPerfilAdmin;
    },
    updateTokenAdmin(state, newToken){
        state.token='Bearer '+newToken;
        state.dateToken = new Date().getTime();
    },
    updateSolicitudAdmin(state, ns){
        state.solicitudAdmin= ns;
    },
    updateObsEntregable(state, newObsEntregable){
        state.infoCurrentTramite.obsEntregable = newObsEntregable;
    },
    updateObsRequisitos(state, newObsRequisitos){
        state.infoCurrentTramite.obsRequisitos = newObsRequisitos;
    },
    updateIsWorkingAdmin(state, newIsWorkingAdmin){
        state.isWorkingAdmin = newIsWorkingAdmin;
    },
    updateEntregablesCurrentTramite(state, newEntregablesCurrentTramite){
        state.infoCurrentTramite.entregables = newEntregablesCurrentTramite;
    },
    updateEstadoEntregablesCurrentTramite(state, newEstado){
        state.infoCurrentTramite.estadoEnts = newEstado;
    },
    addfileB64Admin (state, newfileB64Admin) {
        state.filesB64.requisitos.push(newfileB64Admin);
    },
    updateOrdenPagoCurrentTramite (state, ordenPago) {
        if(ordenPago!=null){
            state.serie=ordenPago.folio.substring(0, 3);
            state.folio=ordenPago.folio.substring(3);
        }else{
            state.serie="";
            state.folio="";
        }
    },
    updateRequisitoCurrentTramite(state, newRequisitoCurrentTramite){
        for(var idx = 0; state.infoCurrentTramite.requisitos.length > idx; idx++){
            if(state.infoCurrentTramite.requisitos[idx].idRequisito == newRequisitoCurrentTramite.idRequisito){
                state.infoCurrentTramite.requisitos[idx] = newRequisitoCurrentTramite;
            }
        }
    },
    updateTramitesPorRevisar(state, newTramitesPorRevisar){
        state.porRevisarInfo.tramites = newTramitesPorRevisar;
    },
    addTramiteToTramitesPorRevisar(state, newTram){
        state.porRevisarInfo.tramites.push(newTram)
    },
    updateCategorias (state, newCategorias) {
        state.categorias = newCategorias;
    },
    updatePorRevisarInfo (state, newPorRevisarInfo) {
        state.porRevisarInfo = newPorRevisarInfo;
    },
    deleteServiceFromTramitesResultAdmin(state, idUserService){
        for(var i=0; i<state.porRevisarInfo.tramites.length; i++){
            if(state.porRevisarInfo.tramites[i].idUserService == idUserService){
                state.porRevisarInfo.tramites.splice(i, 1); //remueve un elemento a partir del indice indicado
                state.porRevisarInfo.total = state.porRevisarInfo.total-1;
            }
        }
    },
    updateInfoCurrentTramite (state, newInfoCurrentTramite) {
        state.infoCurrentTramite = newInfoCurrentTramite;
        if(newInfoCurrentTramite.pago.ordenPago!=null && newInfoCurrentTramite.pago.ordenPago!=''){
            state.serie=JSON.parse(newInfoCurrentTramite.pago.ordenPago).folio.substring(0, 3);
            state.folio=JSON.parse(newInfoCurrentTramite.pago.ordenPago).folio.substring(3);
        }else{
            state.serie="";
            state.folio="";
        }
    },
    updateUrlFirmaAdmin(state, newUrl){
        state.urlFirmaAdmin = newUrl;
    },
    updateIsShowModalFirmaAdmin(state, newIs){
        state.isShowModalFirmaAdmin = newIs;
    }
    
}

export default {
    state,
    mutations
}